import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonAvatar,
  IonRow,
} from "@ionic/react";

import React from "react";
import { useLocation } from "react-router-dom";
import {
  notificationsOutline,
  notificationsSharp,
  cashOutline,
  cashSharp,
  cardOutline,
  cardSharp,
  businessOutline,
  businessSharp,
  ticketOutline,
  ticketSharp,
  personRemoveOutline,
  personRemoveSharp,
  settingsOutline,
  settingsSharp,
  logOutOutline,
  logOutSharp,
  bedOutline,
  bedSharp,
  calendarOutline,
  calendarSharp
} from "ionicons/icons";
import "./Menu.css";
import AuthService from "../services/auth.service";
import { useTranslation, withTranslation } from "react-i18next";

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}
//const { t, i18n } = useTranslation();

const Menu: React.FC = () => {
  const location = useLocation();
  const currentUser = AuthService.getCurrentUser();
  const { t } = useTranslation();

  const appPages: AppPage[] = [
    {
      title: t("announcements"),
      url: "/page/anuncios",
      iosIcon: notificationsOutline,
      mdIcon: notificationsSharp,
    },
    //{
    //  title: 'Documentos',
    //  url: '/page/Outbox',
    //  iosIcon: documentsOutline,
    //  mdIcon: documentsSharp
    //},
    {
      title: t("my-finances"),
      url: "/page/finanzas",
      iosIcon: cashOutline,
      mdIcon: cashSharp,
    },
    {
      title: t("payments"),
      url: "/page/pagos",
      iosIcon: cardOutline,
      mdIcon: cardSharp,
    },
    {
      title: "Airbnb Reservaciones",
      url: "/page/airbnb",
      iosIcon: bedOutline,
      mdIcon: bedSharp,
    },
    {
      title: t("amenities"),
      url: "/page/areascomunes",
      iosIcon: businessOutline,
      mdIcon: businessSharp,
    },
    /****Pendiente***{
      title: 'Encuestas',
      url: '/tabs',
      iosIcon: statsChartOutline,
      mdIcon: statsChartSharp
    },
    {
      title: 'Trasparencia',
      url: '/tabs',
      iosIcon: searchCircleOutline,
      mdIcon: searchCircleSharp
    },*/
    {
      title: t("tickets"),
      url: "/page/tickets",
      iosIcon: ticketOutline,
      mdIcon: ticketSharp,
    },
    {
      title: t("debtors"),
      url: "/page/morosos",
      iosIcon: personRemoveOutline,
      mdIcon: personRemoveSharp,
    },
    {
      title: t("visitors"),
      url: "/page/visitantes",
      iosIcon: calendarOutline,
      mdIcon: calendarSharp,
    },
    {
      title: t("settings"),
      url: "/page/configuracion",
      iosIcon: settingsOutline,
      mdIcon: settingsSharp,
    },
    {
      title: t("logout"),
      url: "/logout",
      iosIcon: logOutOutline,
      mdIcon: logOutSharp,
    },
  ];

  //const { t, i18n } = useTranslation();
  if(!currentUser.condominio)
    return <></>

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonAvatar>
            <img
              alt="Silhouette of a person's head"
              src="https://admin.vittapp.com/app-assets/images/logo/logo.png"
            />
          </IonAvatar>
          <IonListHeader>VittApp</IonListHeader>
          <IonRow>
            <IonNote>{currentUser.condominio.empresa}</IonNote>
          </IonRow>
          <IonRow>
            <IonNote>{currentUser.username}</IonNote>
          </IonRow>

          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={
                    location.pathname === appPage.url ? "selected" : ""
                  }
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon slot="start" icon={appPage.iosIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default withTranslation()(Menu);
