import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonItem,
  IonSpinner,
  IonButton,
  IonButtons,
  IonMenuButton,
} from "@ionic/react";
import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import AuthService from "../services/auth.service";
import { getRandomColor, createImageFromInitials } from "../components/Utils";
import "./Pages.css";
import { useTranslation, withTranslation } from "react-i18next";

const GET_CATEGORIAS_BLOG = gql`
  query ($condominioId: Int!) {
    viewer {
      allCategoriasBlogs(filters: { condominioId: $condominioId }) {
        edges {
          node {
            id
            nombre
            condominioId
            imagen
            descripcion
          }
        }
      }
    }
  }
`;

interface PageProps {
  pageName: string | undefined;
}

const AnunciosPage: React.FC<PageProps> = ({ pageName }) => {
  const { t } = useTranslation();
  const [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser());
  const [condominioId, setCondominioId] = useState(
    currentUser.condominioId ? currentUser.condominioId : 0
  );

  const { loading, error, data } = useQuery(GET_CATEGORIAS_BLOG, {
    variables: { condominioId: condominioId },
    onCompleted(data) {
      //setIsLoading(false);
      //reset();
    },
    onError(error) {
      //reset();
    },
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>
            {t("categories")} [{t("announcements")}]
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {loading ? (
          <div className="spin">
            <IonSpinner></IonSpinner>
          </div>
        ) : (
          data.viewer.allCategoriasBlogs.edges.map((record: any) => (
            <IonCard key={record.node.id}>
              {record.node.imagen ? (
                <img
                  style={{ maxWidth: "100%", height: "186px", width: "100%" }}
                  src={`https://admin.vittapp.com/${record.node.imagen}`}
                />
              ) : (
                <img
                  style={{ maxWidth: "100%", height: "186px", width: "100%" }}
                  src={createImageFromInitials(
                    240,
                    record.node.titulo,
                    getRandomColor()
                  )}
                />
              )}
              <IonCardHeader>
                <IonCardTitle>{record.node.nombre}</IonCardTitle>
              </IonCardHeader>

              <IonCardContent>{record.node.descripcion}</IonCardContent>
              <IonItem>
                <IonButton
                  routerLink={`/page/anuncios/${record.node.id}`}
                  fill="outline"
                  slot="end"
                >
                  {t("show-category")}
                </IonButton>
              </IonItem>
            </IonCard>
          ))
        )}
      </IonContent>
    </IonPage>
  );
};

export default withTranslation()(AnunciosPage);
