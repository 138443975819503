class AuthService {
  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("authToken");
  }

  getCurrentUser() {
    if (localStorage.getItem("user")) {
      return JSON.parse(localStorage.getItem("user"));
    } else {
      return false;
    }
  }

  loggedIn() {
    if (localStorage.getItem("user")) {
      return true;
    } else {
      return false;
    }
  }

  isDarkModeEnable() {
    if (localStorage.getItem("darkmode")) {
      if (JSON.parse(localStorage.getItem("darkmode")) === true) {
        return JSON.parse(localStorage.getItem("darkmode"));
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}

export default new AuthService();
