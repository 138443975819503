import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonItem,
  IonIcon,
  IonSpinner,
  IonButton,
  IonButtons,
  IonMenuButton,
  IonLabel,
  useIonViewWillEnter,
  IonBadge,
  IonList,
  IonGrid,
  IonRow,
  IonCol,
  useIonAlert,
} from "@ionic/react";
import React, { useState } from "react";
import { add as addIcon, trash as trashIcon, qrCode as qrCodeIcon, create as createIcon } from "ionicons/icons";
import { gql, useQuery, useMutation } from "@apollo/client";
import Moment from "moment";
import "moment/locale/es";
import AuthService from "../services/auth.service";
import { useTranslation } from "react-i18next";
import "./Pages.css";

const GET_USER = gql`
query usuario($usuarioId: String) {
  viewer {
    usuario(usuarioId: $usuarioId) {
      habitaResidente {
        residente {
          bitacoraVisitantes {
            edges {
              node {
                id
                visitanteId
                visitante {
                  id
                  nombre
                  vehiculo
                }
                fechaVisita
                fechaSalida
                fechaRegistro
                residente {
                  id
                  nombre
                  paterno
                  materno
                }
                usuario {
                  nombre
                  materno
                  paterno
                }
                condominio {
                  id
                  empresa
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

const DELETE_VISITOR = gql`
  mutation deleteBitacoraVisitante($id: ID!) {
    deleteBitacoraVisitante(id: $id) {
      success
      bitacoravisitante {
        id
      }
    }
  }
`;

const GENERATE_QR_IMAGE = gql`
mutation generateQrInvitationMutation($ticketId: String!) {
  generateQrInvitation(ticketId: $ticketId) {
    success
    fileName
  }
}
`;

interface PageProps {
  pageName: string | undefined;
}

const VisitantesPage: React.FC<PageProps> = ({ pageName }, location) => {
  const { t } = useTranslation();
  const [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser());
  const [visitors, setVisitors] = useState<any[]>([]);
  const [bitacoraVisitanteId, setBitacoraVisitanteId] = useState('');
  const [lang, setLang] = useState<string>(
    localStorage.getItem("langSelect")
      ? localStorage.getItem("langSelect")!
      : "en"
  );
  const [presentAlert] = useIonAlert();
  const [visitas, setVisitas] = useState([]);

  const { loading, error, data, refetch } = useQuery(GET_USER, {
    variables: { usuarioId: currentUser.id },
    onCompleted(data) {
      if (data.viewer.usuario && data.viewer.usuario &&
        data.viewer.usuario.habitaResidente && data.viewer.usuario.habitaResidente.residente
        && data.viewer.usuario.habitaResidente.residente.bitacoraVisitantes) {
        setVisitas(
          data.viewer.usuario.habitaResidente.residente.bitacoraVisitantes.edges
        );
        processVisitors(data.viewer.usuario.habitaResidente.residente.bitacoraVisitantes.edges)
      }
    },
    onError(error) {
      //reset();
    },
  });

  useIonViewWillEnter(() => {
    refetch().then(({ data }) => {
      if (data.viewer.usuario && data.viewer.usuario &&
        data.viewer.usuario.habitaResidente && data.viewer.usuario.habitaResidente.residente
        && data.viewer.usuario.habitaResidente.residente.bitacoraVisitantes) {
        setVisitas(
          data.viewer.usuario.habitaResidente.residente.bitacoraVisitantes.edges
        );
      }
    });
  });


  function processVisitors(data: any) {
    setVisitors([]);
    data.map((input: any) => {
      setVisitors((current) => [
        ...current,
        {
          id: input.node.id,
          nombre: `${input.node.nombre} ${input.node.paterno} ${input.node.materno}`,
          fechaVisita: input.node.fechaVisita,
          visitante: input.node.visitante.nombre,
          estatus: input.node.estatus,
          //cargo: input.node.monto,
        },
      ]);
    });
  }

  const [generateQrInvitation, { data: DataQr, loading: loadingQr, error: errorQr }] = useMutation(GENERATE_QR_IMAGE,
    {
      onError(error) {
        reset();

      },
      onCompleted(data) {
        const a = document.createElement('a')
        a.download = `${bitacoraVisitanteId}.png`
        a.href = `data:image/png;base64,${data.generateQrInvitation.fileName}`
        a.click()
        setBitacoraVisitanteId('null')
      }

    }
  );

  const handleGenerateQRImage = (id: string) => {
    setBitacoraVisitanteId(id)
    generateQrInvitation({
      variables: {
        ticketId: id
      }
    });
  };

  const [
    deleteVisita,
    {
      data: dataReservacion,
      loading: loadingReservacion,
      error: errorReservacion,
      reset,
    },
  ] = useMutation(DELETE_VISITOR, {
    onError(error) {
      reset();
    },
    onCompleted() {
      refetch().then(({ data }) => {
        if (data.viewer.usuario && data.viewer.usuario &&
          data.viewer.usuario.habitaResidente && data.viewer.usuario.habitaResidente.residente
          && data.viewer.usuario.habitaResidente.residente.bitacoraVisitantes) {
          setVisitas(
            data.viewer.usuario.habitaResidente.residente.bitacoraVisitantes.edges
          );
        }
      });
    },
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{t("visitors")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {loading ? (
          <div className="spin">
            <IonSpinner></IonSpinner>
          </div>
        ) : (
          <>
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>{t("visitors")}</IonCardTitle>
              </IonCardHeader>
              <IonButton
                fill="outline"
                expand="block"
                routerLink={"/page/visitantes/create"}
              >
                <IonIcon icon={addIcon} slot="start" />
                {t("new-visitor")}
              </IonButton>
            </IonCard>

            {visitas &&
              visitas.map(
                (record: any) => (
                  <IonCard key={record.node.id}>
                    <img alt="Silhouette of mountains" src="https://ionicframework.com/docs/img/demos/card-media.png" height={150} width={'100%'} />
                    <IonCardHeader>
                      <IonCardSubtitle>
                        {Moment(record.node.fechaVisita).locale(lang).format("LLLL")}{" "}
                      </IonCardSubtitle>
                      <IonCardTitle>
                        {record.node.visitante.nombre}
                      </IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent>{record.node.solucion}</IonCardContent>
                    <IonGrid>
                      <IonRow class="ion-justify-content-end">
                        <IonCol>

                          <IonButton fill="outline" onClick={() => handleGenerateQRImage(record.node.id)} disabled={loadingQr}>
                            {loadingQr && (record.node.id === bitacoraVisitanteId) ?
                              <IonSpinner name="dots" className="ion-margin-end" style={{width:'18.9px', height: '18.9px'}}></IonSpinner>                              
                              :
                              <IonIcon icon={qrCodeIcon} className="ion-margin-end" />
                            }
                            Descargar QR
                          </IonButton>
                          <IonButton
                            fill="outline"
                            routerLink={`/page/visitantes/${record.node.id}`}
                          >
                            <IonIcon icon={createIcon} className="ion-margin-end" />
                            {t("Editar")}
                          </IonButton>
                          <IonButton
                            fill="outline"
                            color={"danger"}
                            onClick={(event) =>
                              presentAlert({
                                header: `Estas seguro de eliminar la visita de ${record.node.visitante.nombre}?`,
                                cssClass: "custom-alert",
                                buttons: [
                                  {
                                    text: "Cancelar",
                                    role: "cancel",
                                    cssClass: "alert-button-cancel",
                                  },
                                  {
                                    text: "Aceptar",
                                    role: "confirm",
                                    cssClass: "alert-button-confirm",
                                    handler: () => {
                                      deleteVisita({
                                        variables: { id: record.node.id },
                                      });
                                    },
                                  },
                                ],
                              })
                            }
                          >
                            <IonIcon icon={trashIcon} className="ion-margin-end" />
                            Eliminar
                          </IonButton>

                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCard>
                )
              )}
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default VisitantesPage;
