import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonRow,
  IonCol,
  IonGrid,
  IonButtons,
  IonMenuButton,
  IonSpinner,
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/react";
import ExploreContainer from "../../components/ExploreContainer";
import "./Tab1.css";
import { gql, useQuery, useMutation, useLazyQuery } from "@apollo/client";
import AuthService from "../../services/auth.service";
import Moment from "moment";
import { refresh as refreshIcon } from "ionicons/icons";
import { RefresherEventDetail } from "@ionic/core";
import { useTranslation, withTranslation } from "react-i18next";

const GET_USER = gql`
  query usuario($usuarioId: String) {
    viewer {
      usuario(usuarioId: $usuarioId) {
        id
        habitaResidente {
          id
          habitacion {
            id
            nombre
            superficie
            cuota
            referenciaBancaria
            saldo
            habitaResidente {
              residente {
                id
                nombre
                paterno
                materno
                email
              }
            }
            tipoVivienda {
              id
              nombre
            }
            condominio {
              id
              empresa
            }
            torre {
              id
              nombre
              condominio {
                id
                empresa
              }
            }
            cuotas(filters: { estatusNe: "P" }) {
              edges {
                node {
                  id
                  residente {
                    id
                    nombre
                  }
                  formaCuota
                  monto
                  fecha
                  concepto
                  estatus
                  cuotaXMetro
                  tipoCuotaId
                  contTipoCuota {
                    id
                    nombre
                  }
                  pagos {
                    edges {
                      node {
                        id
                        recibo
                        monto
                      }
                    }
                  }
                  recargos(filters: { estatus: "D" }) {
                    edges {
                      node {
                        id
                        deudaDe
                        deudaA
                        otrasDeudasVigentes
                        cantidad
                        cantidadTipo
                        aplicarA
                        soloMayoresA
                        conContCuotas {
                          id
                          monto
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const pagoCompletoStyle = {
  backgroundColor: "rgba(60, 179, 113, 0.2)",
};
const pagoIncompletoStyle = {
  backgroundColor: "rgba(255, 99, 71, 0.2)",
};

const EstadoDeCuentaTab: React.FC = () => {
  const { t } = useTranslation();
  const [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser());
  const [condominioId, setCondominioId] = useState(
    currentUser.condominioId ? currentUser.condominioId : 0
  );
  const [saldoAfavor, setSaldoAfavor] = useState(0);
  const [hasSaldo, setHasSaldo] = useState(false);
  const [showAbonoColumn, setShowAbonoColumn] = useState(false);
  const [cuotasPendientes, setCuotasPendientes] = useState<any[]>([]);
  const [totalPagoPendiente, setTotalPagoPendiente] = useState(0);
  const [saldo, setSaldo] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [loadEstadoDeCuenta, { called, loading, data }] = useLazyQuery(
    GET_USER,
    {
      fetchPolicy: "cache-and-network",
      variables: { usuarioId: currentUser.id },
      onCompleted(data) {
        console.log(data);
        setIsLoading(false);
        setCuotasPendientes([]);
        let cuotas = [];
        let newfield: any;
        let currentContacto;
        let recargo = 0;
        let total = 0;
        let totalRecargos = 0;
        let recargosAnteriores = 0;
        let abonosAnteriores = 0;
        let hasSaldo = data.viewer.usuario.habitaResidente.habitacion.saldo
          ? true
          : false;
        let saldo = hasSaldo
          ? data.viewer.usuario.habitaResidente.habitacion.saldo
          : 0;
        let saldoRestante = saldo;
        let pagoPorCuota = 0;

        setSaldoAfavor(saldo);
        setHasSaldo(hasSaldo);

        data.viewer.usuario.habitaResidente.habitacion.cuotas.edges.map(
          (input: any, index: any) => {
            //recargo = 0;
            totalRecargos = 0;
            recargosAnteriores = 0;
            abonosAnteriores = 0;
            recargo = 0;
            //saldo = 0;
            pagoPorCuota = 0;

            input.node.recargos.edges.map((value: any, index: any) => {
              recargosAnteriores += value.node.cantidad;
            });

            input.node.pagos.edges.map((value: any, index: any) => {
              abonosAnteriores += value.node.monto;
            });

            if (abonosAnteriores > 0) {
              setShowAbonoColumn(true);
            }

            newfield = {
              id: input.node.id,
              fecha: input.node.fecha,
              concepto: input.node.concepto,
              cargo: input.node.monto,
              interes: recargo,
              nuevoCargo: input.node.monto,
              anteriorRecargo: recargosAnteriores,
              nuevoRecargo: recargo,
              nuevoTotal:
                recargo +
                input.node.monto +
                recargosAnteriores -
                (abonosAnteriores + saldoRestante),
              isAdded: true,
              focus: "",
              restante:
                recargo +
                input.node.monto +
                recargosAnteriores -
                (abonosAnteriores + saldoRestante),
              pago: 0,
              descuento: 0,
              hasDiscount: false,
              discountTitle: "",
              abono: abonosAnteriores,
            };
            total +=
              recargo +
              input.node.monto +
              recargosAnteriores -
              (abonosAnteriores + saldoRestante);
            setCuotasPendientes((current) => [
              ...current,
              {
                id: input.node.id,
                fecha: input.node.fecha,
                concepto: input.node.concepto,
                cargo: input.node.monto,
                interes: recargo,
                nuevoCargo: input.node.monto,
                anteriorRecargo: recargosAnteriores,
                nuevoRecargo: recargo,
                categoria: input.node.concepto,
                nuevoTotal:
                  recargo +
                  input.node.monto +
                  recargosAnteriores -
                  (abonosAnteriores + saldoRestante),
                isAdded: true,
                focus: "",
                restante:
                  recargo +
                  input.node.monto +
                  recargosAnteriores -
                  (abonosAnteriores + saldoRestante),
                pago: 0,
                descuento: 0,
                hasDiscount: false,
                discountTitle: "",
                abono: abonosAnteriores,
              },
            ]);
            //setTotalPagoPendiente();
            setTotalPagoPendiente(total * -1);
            setSaldo(total);
            console.log(
              "newfield",
              recargo,
              input.node.monto,
              recargosAnteriores
            );
          }
        );

        console.log(cuotasPendientes);

        //reset();
      },
      onError(error) {
        //reset();
      },
    }
  );

  const onRefreshClick = () => {
    setIsLoading(true);
    loadEstadoDeCuenta();
  };

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    console.log("Begin async operation");
    event.detail.complete();
    setIsLoading(true);
    loadEstadoDeCuenta();
  }

  useEffect(() => {
    loadEstadoDeCuenta();
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle size="large">
            {t("outstanding-balance")} [{t("my-finances")}]
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onRefreshClick}>
              <IonIcon slot="icon-only" icon={refreshIcon}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonRefresher
          slot="fixed"
          onIonRefresh={doRefresh}
          pullFactor={0.5}
          pullMin={100}
          pullMax={200}
        >
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonGrid fixed={true}>
          {isLoading ? (
            <div className="spin">
              <IonSpinner></IonSpinner>
            </div>
          ) : (
            <>
              <IonRow>
                <IonCol size="2">{t("date")}</IonCol>
                <IonCol size="2">{t("description")}</IonCol>
                <IonCol size="2">{t("category")}</IonCol>
                <IonCol size="2">{t("charge")}</IonCol>
                <IonCol size="2">{t("surcharges")}</IonCol>
                <IonCol size="2">{t("total")}</IonCol>
              </IonRow>
              {cuotasPendientes.map((input: any, index: any) => (
                <IonRow key={index}>
                  <IonCol size="2">
                    {Moment(input.fecha).format("DD-MMM-YYYY")}
                  </IonCol>
                  <IonCol size="2">{input.concepto}</IonCol>
                  <IonCol size="2">{input.categoria}</IonCol>
                  <IonCol size="2">
                    {new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                    }).format(input.cargo)}
                  </IonCol>
                  <IonCol size="2">
                    {new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                    }).format(input.anteriorRecargo)}
                  </IonCol>
                  <IonCol size="2">
                    {new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                    }).format(input.nuevoTotal)}
                  </IonCol>
                </IonRow>
              ))}
              <IonRow>
                <IonCol size="8"></IonCol>
                <IonCol
                  size="4"
                  style={
                    totalPagoPendiente > 0
                      ? pagoCompletoStyle
                      : totalPagoPendiente === 0
                      ? pagoCompletoStyle
                      : pagoIncompletoStyle
                  }
                >
                  {new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(totalPagoPendiente)}
                </IonCol>
              </IonRow>
            </>
          )}
        </IonGrid>
        {!isLoading && (
          <p style={{ textAlign: "center" }}>{t("legal-information")}</p>
        )}
      </IonContent>
    </IonPage>
  );
};

export default withTranslation()(EstadoDeCuentaTab);
